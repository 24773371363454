var nearbyMap;
var nearbyMapElement;
var nearbyMapMarkers = [];
var nearbyMapMarkerWindows = [];

$(function() {
    if($('.js-nearby').length) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(nearbyLocationHandle, nearbyLocationError);
        }
        else {
            nearbyLocationError(false);
        }
    }
});

function nearbyLocationHandle(position)
{
    $('.js-nearby-latitude').val(position.coords.latitude);
    $('.js-nearby-longitude').val(position.coords.longitude);
    $('.js-nearby').submit();
}
function nearbyLocationError(error)
{
    alert("Sorry, we haven't been able to locate you. Please wait whilst we take you to a page where you can search for your location.");
    window.location = $('.js-nearby').attr('data-search-url');
}

$(function() {
    nearbyMapElement = $('.js-nearby-map');
    if(nearbyMapElement.length) {
        nearbyMap = new google.maps.Map(nearbyMapElement[0], {
            center: {
                lat: parseFloat(nearbyMapElement.attr('data-lat')),
                lng: parseFloat(nearbyMapElement.attr('data-lon'))
            },
            zoom: 12
        });
        if(nearbyMapData.length) {
            $.each( nearbyMapData, function( index, branch ) {
                var mapMarkerWindow = new google.maps.InfoWindow({
                    content: branch.window
                });
                var mapMarker = new google.maps.Marker({
                    position: {
                        lat: parseFloat(branch.latitude),
                        lng: parseFloat(branch.longitude)
                    },
                    title: branch.name,
                    icon: '//chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + branch.connected + '|000000'
                });
                mapMarker.addListener('click', function() {
                    for (var i=0;i<nearbyMapMarkerWindows.length;i++) {
                        nearbyMapMarkerWindows[i].close();
                    }
                    mapMarkerWindow.open(nearbyMap, mapMarker);
                });
                mapMarker.setMap(nearbyMap);
                nearbyMapMarkers.push(mapMarker);
                nearbyMapMarkerWindows.push(mapMarkerWindow);
            });

        }
    }
});

$(function() {

    /**
     * Handle sizing of transaction items
     */
    transactionResizeItems();
    $(window).resize(function () {
        transactionResizeItems();
    });

    /**
     * Recalculate points totals on page load
     */
    transactionCalculateTotal();

    /**
     * On click of transaction item (singular) - toggle value & manage active class
     */
    $('.js-transaction-item-single').click(function (e) {
        $(this).removeClass('transaction-item-active');
        if($(".js-transaction-item-quantity", this).val()=="1") {
            $(".js-transaction-item-quantity", this).val("0");
        }
        else {
            $(".js-transaction-item-quantity", this).val("1");
            $(this).addClass('transaction-item-active');
        }
        transactionCalculateTotal();
        clearSelection();
        return false;
    });

    /**
     * On click of transaction item (multiple) - increase quantity
     */
    $('.js-transaction-item-multiple').click(function (e) {
        if (e.target !== this && ($(e.target).is('button') || $(e.target).is('button span') || $(e.target).is('input'))) {
            return false;
        }
        $(this).removeClass('transaction-item-active');
        if($.isNumeric($(".js-transaction-item-quantity", this).val()) && parseInt($(".js-transaction-item-quantity", this).val())>0) {
            $(".js-transaction-item-quantity", this).val((parseInt($(".js-transaction-item-quantity", this).val())+1));
        }
        else {
            $(".js-transaction-item-quantity", this).val('1');
        }
        if(parseInt($(".js-transaction-item-quantity", this).val())>0) {
            $(this).addClass('transaction-item-active');
        }
        transactionCalculateTotal();
        clearSelection();
        return false;
    });

    /**
     * On change of transaction item multiple quantity - manage active class
     */
    $('.js-transaction-item-multiple .js-transaction-item-quantity').change(function (e) {
        var transactionItemWrapper = $(this).closest('.transaction-item');
        transactionItemWrapper.removeClass('transaction-item-active');
        if(!parseInt($(this).val()) || $(this).val()<1) {
            $(this).val('');
        }
        if($(this).val()!="") {
            var val = parseInt($(this).val());
            $(this).val(val);
        }
        if(parseInt($(this).val())>0) {
            transactionItemWrapper.addClass('transaction-item-active');
        }
        transactionCalculateTotal();
        clearSelection();
        return false;
    });

    /**
     * On click of transaction item multiple quantity plus / minus
     */
    $('.js-transaction-item-quantity-minus').click(function (e) {
        var transactionItemWrapper = $(this).closest('.transaction-item');
        var transactionItemQuantityField = $('.js-transaction-item-quantity', transactionItemWrapper);
        $(transactionItemWrapper).removeClass('transaction-item-active');
        if($.isNumeric($(transactionItemQuantityField).val()) && parseInt($(transactionItemQuantityField).val())>=2) {
            $(transactionItemQuantityField).val((parseInt($(transactionItemQuantityField).val())-1));
        }
        else {
            $(transactionItemQuantityField).val('');
        }
        if(parseInt($(transactionItemQuantityField).val())>0) {
            $(transactionItemWrapper).addClass('transaction-item-active');
        }
        transactionCalculateTotal();
        clearSelection();
        return false;
    });
    $('.js-transaction-item-quantity-plus').click(function (e) {
        var transactionItemWrapper = $(this).closest('.transaction-item');
        var transactionItemQuantityField = $('.js-transaction-item-quantity', transactionItemWrapper);
        $(transactionItemWrapper).removeClass('transaction-item-active');
        if($.isNumeric($(transactionItemQuantityField).val()) && parseInt($(transactionItemQuantityField).val())>=1) {
            $(transactionItemQuantityField).val((parseInt($(transactionItemQuantityField).val())+1));
        }
        else {
            $(transactionItemQuantityField).val('1');
        }
        if(parseInt($(transactionItemQuantityField).val())>0) {
            $(transactionItemWrapper).addClass('transaction-item-active');
        }
        transactionCalculateTotal();
        clearSelection();
        return false;
    });

});

/**
 * Function to recalculate transaction points total
 */
function transactionCalculateTotal() {
    var transactionQuantityFields = $(".js-transaction-item-quantity");
    var transactionPointsTotal = 0;
    transactionQuantityFields.each(function() {
        var transactionItemQuantity = parseInt($(this).val());
        var transactionItemPoints = parseInt($(this).attr('data-points'));
        if(transactionItemPoints>0 && transactionItemQuantity>0) {
            transactionPointsTotal = transactionPointsTotal + (transactionItemQuantity * transactionItemPoints );
        }
    });
    $('.js-transaction-summary-points').text(transactionPointsTotal);
}

/**
 * Function to resize transaction items
 */
function transactionResizeItems() {
    var transactionItemRows = $(".transaction-items-row");
    if(transactionItemRows.length) {
        transactionItemRows.each(function() {
            $('.transaction-item', this).css('height', '');
            var transactionItemMaxHeight = 0;
            $('.transaction-item', this).each(function() {
                if($(this).outerHeight()>0 && $(this).outerHeight()>transactionItemMaxHeight) {
                    transactionItemMaxHeight = $(this).outerHeight();
                }
            });
            $('.transaction-item', this).css('height', transactionItemMaxHeight.toString() + 'px');
        });
    }
}

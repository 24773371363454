

$(function() {

    $('.trigger-event-popup').on('click', function(){

        var event_id = $(this).attr('data-id');

        $.get( '/calendar/event/'+event_id, function( data ) {
            $('#event_popup').modal();
            $('#event_popup').on('shown.bs.modal', function(){
                $('#event_popup .load_event_modal').html(data);
            });
            $('#event_popup').on('hidden.bs.modal', function(){
                $('#event_popup .modal-body').data('');
            });
        });

    });

});


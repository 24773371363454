$(function() {

    /**
     * Handle toggle of map / list
     */
    $('.js-branchesmap-map-toggle').click(function(e) {
        $('.js-branchesmap-list-toggle').removeClass('active');
        if(!$(this).hasClass('active')) {
            $(this).addClass('active');
        }
        $('.js-branchesmap-list').hide();
        $('.js-branchesmap-map').show();
        e.preventDefault();
        return false;
    });
    $('.js-branchesmap-list-toggle').click(function(e) {
        $('.js-branchesmap-map-toggle').removeClass('active');
        if(!$(this).hasClass('active')) {
            $(this).addClass('active');
        }
        $('.js-branchesmap-map').hide();
        $('.js-branchesmap-list').show();
        e.preventDefault();
        return false;
    });

    initialize_map();

    $('#accordion').on('shown.bs.collapse', function(e) {
      initialize_map();
    });

});

/**
 * Draw the map
 */
function initialize_map() {
  var branchesMap;
  var branchesMapElement;
  var branchesMapLatLngs = [];
  var branchesMapLatLngBounds;
  var branchesMapMarkers = [];
  var branchesMapMarkerWindows = [];

  branchesMapElement = $('.js-branchesmap');
  if(branchesMapElement.length && branchesMapData.length) {
      branchesMap = new google.maps.Map(branchesMapElement[0], {
          center: {
              lat: parseFloat(branchesMapData[0].latitude),
              lng: parseFloat(branchesMapData[0].longitude)
          },
          zoom: 1
      });
      $.each( branchesMapData, function( index, branch ) {
          var mapMarkerWindow = new google.maps.InfoWindow({
              content: branch.window
          });
          var mapMarker = new google.maps.Marker({
              position: {
                  lat: parseFloat(branch.latitude),
                  lng: parseFloat(branch.longitude)
              },
              title: branch.name
          });
          mapMarker.addListener('click', function() {
              for (var i=0;i<branchesMapMarkerWindows.length;i++) {
                  branchesMapMarkerWindows[i].close();
              }
              mapMarkerWindow.open(branchesMap, mapMarker);
          });
          mapMarker.setMap(branchesMap);
          branchesMapMarkers.push(mapMarker);
          branchesMapMarkerWindows.push(mapMarkerWindow);
          branchesMapLatLngs.push(new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude)));
      });
      branchesMapLatLngBounds = new google.maps.LatLngBounds();
      branchesMapLatLngs.forEach(function(latLng){
          branchesMapLatLngBounds.extend(latLng);
      });
      branchesMap.setCenter(branchesMapLatLngBounds.getCenter());
      branchesMap.fitBounds(branchesMapLatLngBounds);
  }
}

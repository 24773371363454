

$(function() {
  $('.js-profile-show-login').click(function(e) {
      $('.js-profile-wrap-register').slideUp();
      $('.js-profile-wrap-login').slideDown();
      e.preventDefault();
      return false;
  });

  $('.js-profile-show-register').click(function(e) {
      $('.js-profile-wrap-login').slideUp();
      $('.js-profile-wrap-register').slideDown();
      e.preventDefault();
      return false;
  });
});

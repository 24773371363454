function clearSelection() {
    if ( document.selection ) {
        document.selection.empty();
    } else if ( window.getSelection ) {
        window.getSelection().removeAllRanges();
    }
}



$(function() {
    navbarNavHandleDropdowns();
    $(window).resize(function () {
        navbarNavHandleDropdowns();
    });

    /**
     * Attach datepickers
     */
    $('.js-datepicker').datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
        forceParse: true,
        orientation: 'bottom',
        clearBtn: true
    });

    $('.js-datepicker-dob').datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
        forceParse: true,
        orientation: 'bottom',
        clearBtn: true,
        viewMode: "years",
        startView: "decade",
        endDate: "0d",
        maxViewMode: "years"
    });

    $('.js-login-form-trigger').on('click', function(e){
        $('.js-login-form-trigger').slideUp();
        $('.js-login-form-wrap').slideDown();
        $( "#username" ).focus();
        e.preventDefault();
    });

    $('#accordion .collapse.in').prev('.accordion-item-heading').addClass('active');

    $('#accordion')
    .on('show.bs.collapse', function(e) {
      $(e.target).prev('.accordion-item-heading').addClass('active');
    })
    .on('hide.bs.collapse', function(e) {
      $(e.target).prev('.accordion-item-heading').removeClass('active');
    });

    $('#upload_profile_picture').click(function(e) {
      $('#upload_profile_picture_field').click();
      e.preventDefault();
    });

    $('#upload_profile_picture_field').change(function() {
      $('#profile_picture_present').val('true');
      $('.uploading-screen').show();
      $('#profile_picture_upload_form').submit();
    });

});

function navbarNavHandleDropdowns() {
    if($(window).width()<768) {
        $('.navbar-nav .dropdown').addClass('open');
    }
    else {
        $('.navbar-nav .dropdown').removeClass('open');
    }
}

$('body').on('hidden.bs.modal', '.modal', function () {
  $(this).removeData('bs.modal');
  $('.modal-header').html('<h4 class="modal-title">Loading...</h4>');
  $('.modal-body').html("");
});
